.carousel__container {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 5rem;
  }
  
  .carousel__inner {
    display: flex;
    animation: scrollAnimation 80s linear infinite; /* Adjust the duration for the scroll speed */
  }
  
  .carousel__item {
    flex: 0 0 calc(100% / 6 - 10px); /* Display 6 items at a time with spacing between images */
    margin-right: 50px; /* Add spacing between images */
    overflow: hidden; /* Hide any overflowing part of the image */
    border-radius: 1rem;
  }
  
  .carousel__item img {
    width: 100%;
    height: auto;
    object-fit: cover; /* Maintain aspect ratio and cover the entire container */
  }
  
  @keyframes scrollAnimation {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-100% * 6)); /* Adjust the percentage for the number of images displayed */
    }
  }
  

  @media screen and (max-width: 600px) {
    .carousel__container {
      display: none;
    }
  }
