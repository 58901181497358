nav {
  height: 8.5rem;
  width: 100vw;
  display: grid;
  place-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  /* Default background for non-homepages */
  background-color: #010030;
}

nav.homepage {
  background-color: transparent; /* Transparent background for homepage */
}

nav.scrolled {
  background-color: #010030;
  transition: background-color 2s ease;
}

.nav__toggle-btn {
  display: none;
}

.nav__container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  opacity: 100%;
}

.logo {
  width: 500px;
  display: flex;
  align-items: center;
  font-size: 2.5rem;
}

.logoText {
  font-size: 2.5rem;
  margin-left: 1rem;
  font-family: "Montserrat", sans-serif;
}

.nav__links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40rem;
}

.nav__links a {
  transition: var(--transition);
}

.nav__links a:hover {
  color: var(--color--secondary);
  text-decoration: underline;
}

.active-nav {
  position: relative;
}

.active-nav::after {
  content: "";
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  left: calc(50% - 0.75rem);
  transform: rotate(45deg);
  margin-top: 2.4rem;
}

.active-nav-scrolled::after {
  background-color: #010030;
  transition: background-color 2s ease;
}

/*  MEDIA QUERIES (Medium and Small screens)  */

@media screen and (max-width: 1024px) {
  #logo1 {
    width: 30px;
    height: 80px;
  }
  .main {
    height: 100vh;
  }
  .nav__toggle-btn {
    display: inline-block;
    background: transparent;
    font-size: 1.8rem;
    cursor: pointer;
  }

  .nav__toggle-btn svg {
    color: white;
    /* margin-right: 2rem; */
  }

  .nav__toggle-btn path {
    stroke: white !important;
  }

  .closeIcon {
    stroke: white; /* Changes the stroke color */
    fill: white;
  }

  .nav__links {
    position: absolute;
    top: 100%;
    right: -293;
    flex-direction: column;
    gap: 0;
    perspective: 400px;
    margin-right: calc(50% - 2rem);
  }

  .active-nav,
  .active-nav::after {
    display: none;
  }

  .nav__links li {
    height: 4rem;
    width: 100%;
    box-shadow: -2rem 2rem 5rem rgba(0, 0, 0, 0.4);
    animation: navAnimation 600ms ease forwards;
    transform: rotateX(90deg);
    opacity: 0;
    transform-origin: top;
  }

  .nav__links li:nth-child(2) {
    animation-delay: 200ms;
  }

  .nav__links li:nth-child(3) {
    animation-delay: 400ms;
  }

  .nav__links li:nth-child(4) {
    animation-delay: 600ms;
  }

  .nav__links li:nth-child(5) {
    animation-delay: 800ms;
  }

  .nav__links li:nth-child(6) {
    animation-delay: 1000ms;
  }

  @keyframes navAnimation {
    to {
      transform: rotateX(0);
      opacity: 1;
    }
  }

  .nav__links li a {
    background-color: var(--color-primary);
    height: 102%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 5rem 1rem 3rem;
    border-radius: 10px;
  }

  .show__nav {
    display: flex;
  }

  .hide__nav {
    display: none;
  }

  .logo {
    width: 370px;
    /* display: block; */
    /* margin-left: 3rem; */
  }
}

.logoText {
  font-size: 1.7rem;
}
/* SMALL SCREENS */

@media screen and (max-width: 600px) {
  nav {
    height: 5rem;
  }

  #logo1 {
    width: 20px;
    height: 60px;
  }
  .logo {
    /* width: 500px;
    display: flex;
    align-items: flex-start;
    font-size: 2.5rem;
    flex-wrap: nowrap;
    align-content: center;
    flex-direction: row;
    justify-content: space-evenly; */
  }

  .nav__toggle-btn {
    margin-top: 10px;
    font-size: 1.6rem;
  }

  .nav-links a {
    border-radius: 10px;
  }

  .main__header {
    padding: 0rem 0 0;
  }
}
