.trips-contact-form-container {
  max-width: 600px; /* Slightly wider to accommodate more fields */
  margin: 20% auto;
  padding: 20px;
  border: 1px solid #4a7043; /* Dark green border */
  border-radius: 8px;
  background-color: #f5f5f5; /* Light grey background */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.trips-contact-form-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #4a7043; /* Dark green for the heading */
}

.trips-form-section {
  margin-bottom: 20px;
}

.trips-form-section h3 {
  font-size: 1.25rem;
  font-weight: bold;
  color: #4a7043;
  margin-bottom: 15px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.trips-form-group {
  margin-bottom: 15px;
}

.trips-form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.trips-form-group input,
.trips-form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.trips-form-group textarea {
  height: 100px;
  resize: vertical;
}

.trips-radio-group {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.trips-radio-group label {
  display: flex;
  align-items: center;
  font-weight: normal;
}

.trips-radio-group input {
  margin-right: 5px;
}

.trips-number-group {
  display: flex;
  gap: 20px;
}

.trips-number-group div {
  flex: 1;
}

.trips-number-group input {
  width: 100%;
}

.trips-radio-group input {
  width: 0%;
}

.trips-contact-form-container button {
  width: 100%;
  padding: 10px;
  background-color: #6b4e71; /* Purple inspired by Scottish heather */
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.trips-contact-form-container button:hover {
  background-color: #5a3e61; /* Darker purple on hover */
}

.trips-status-message {
  margin-top: 15px;
  text-align: center;
  color: #28a745;
}

.trips-status-message.error {
  color: #dc3545;
}
