.notfound__container {
    margin-top: 10rem;
    text-align: center;
    padding: 8rem;
}

.notfound__container .btn {
    margin-top: 5rem;
    display: inline-block;
}

.notfound__container h2 {
    margin-top: 10rem;   
}