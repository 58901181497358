.main__header {
  width: 100vw;
  height: calc(100vh - 0rem);
  display: grid;
  place-items: center;
  /* margin-top: 7rem; */
  /* background-image: url("../../images/KYLESKU\ .JPG"); */
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
}

.main__header-container {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  gap: 6rem;
}

.main__header-left {
  margin-top: 3rem;
}

.main__header-left h3 {
  margin-bottom: 4rem;
  color: var(--color-secondary);
  font-weight: 600;
}

.main__header-left p {
  margin: 1rem 0 3.5rem;
  font-size: 1.1rem;
}

.main__header-right {
  display: grid;
  place-items: center;
  position: relative;
}

.main__header-image {
  position: relative;
}

#ss {
  margin-bottom: 20px;
}

/* ============================= */

/* SAFARIS */

.safaris {
  margin-top: 12rem;
}

.safaris__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
  margin-top: 4rem;
}

.safaris__safari a {
  margin-top: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.safaris_safari:hover a {
  background-color: var(--color-secondary);
  color: var(--color-gray-600);
}

/* ================================== */

/* VALUES */

.values__container {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 8%;
  margin-top: 10rem;
  /* Added the below CSS Myself for tree */
  /* display: flex;
    justify-content: space-between;
    align-items: center; */
}

.values__image {
  filter: saturate(0.25);
  transition: var(--transition);
}

.values__image:hover {
  filter: saturate(1);
}

.values__right > p {
  margin: 1.5rem 0 5rem;
}

.values__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3.4rem 2.4rem;
}

.card.values__value {
  padding-top: 3.5rem;
  text-align: left;
  position: relative;
}

.card.values__value span {
  position: absolute;
  top: -1.5rem;
}

/* ================================== */

/* FAQs */

.faqs__wrapper {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem 5rem;
  align-items: flex-start;
}

.faq {
  background: var(--color-gray-400);
  padding: 2rem;
  border-radius: 1rem;
  cursor: pointer;
}

.faq div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.faq__icon {
  background: transparent;
  color: var(--color-gray-100);
  font-size: 1.5rem;
  border: 1px solid rgb(146, 146, 146);
  border-radius: 0.5rem;
}

.faq p {
  margin-top: 1.5rem;
}

/* ================================== */

/* TESTIMONIALS*/

.testimonials {
  width: 50rem;
  margin-inline: auto;
}

.testimonials__head {
  justify-content: center;
}

.card.testimonial {
  position: relative;
  text-align: center;
  margin-top: 5rem;
}

.testimonial__avatar {
  width: 4rem;
  height: 4rem;
  border-radius: 1.5rem;
  overflow: hidden;
  border: 2px solid transparent;
  position: absolute;
  top: -2rem;
  left: calc(50% - 2rem);
  box-shadow: 0 1.5rem 2rem;
  transition: var(--transition);
}

.testimonial__avatar:hover .testimonial__avatar {
  border-radius: 50%;
  border-color: var(--color-gray-400);
}

.testimonial__quote {
  font-style: italic;
  margin: 1rem 0 2rem;
}

.card small.testimonial__title {
  margin-top: 0.3rem;
}

.testimonials__btn-container {
  width: fit-content;
  margin: 2.5rem auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.testimonials__btn {
  background: transparent;
  cursor: pointer;
  /* border: 1px solid var(--color-gray-400);
    color: var(--color-gray-400);
    padding: 0.5rem 1.5rem;
    border-radius: 0.5rem; */
}

.testimonials__btn svg {
  fill: var(--color-primary);
  font-size: 1.8rem;
  width: 3rem;
  height: 3rem;
}

/* ================================== */

/* Footer */

footer {
  background-color: var(--color-primary);
  margin-top: 7rem;
  padding-top: 7rem;
  font-size: 0.9rem;
  color: var(--color-gray-100);
}

.footer__container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the first article */
  gap: 4rem;
}

.footer__container article {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content inside the articles */
  gap: 0.7rem;
}

.footer__container article p {
  margin-bottom: 1.5rem;
  font-size: 1rem;
}

.footer__container article h4 {
  margin-bottom: 0.6rem;
  font-size: 1rem;
}

#footerlogo {
  width: 20rem;
  margin-top: -56px;
  margin: auto;
}

.footer__socials {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.footer__socials a {
  color: var(--color-gray-100);
  padding: 0.5rem;
  border: 1px solid black;
  border-radius: 0.6rem;
  transition: var(--transition);
}

.footer__socials a svg {
  color: var(--color-gray-600);
  font-size: 27px;
}

.footer__socials a:hover {
  border-color: var(--color-gray-100);
  background: white;
}

.footer-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.footer-links {
  text-decoration: underline;
}

article a:hover {
  text-decoration: underline;
}

footer .container {
  width: 80%; /* Optional: Adjust the width of the first container */
  margin: 0 auto; /* Optional: Center the first container */
}

small {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content inside the articles */
  margin-bottom: 3rem;
  padding: 5rem;
  font-size: 0.9rem;
}

/* MEDIA QUERIES (Medium Screen) */

@media screen and (max-width: 1024px) {
  /* MAIN HEADER */
  .main__header {
    height: fit-content;
    /* padding: 12rem 0; */
  }

  .main__header-container {
    gap: 0;
  }

  .main__header-circle {
    width: 16rem;
    height: 16rem;
  }
}

/* PROGRAMS */

.safaris__wrapper {
  grid-template-columns: 1fr 1fr;
}

/* VALUES */

.values__container {
  grid-template-columns: 1fr;
  justify-content: center;
  gap: 4rem;
}

.values__wrapper {
  gap: 4rem 3rem;
}

.values__image {
  display: none;
}

/* FAQs */

.faqs__wrapper {
  grid-template-columns: 1fr;
}

/* TESTIMONIALS */

.testimonials {
  width: 75%;
}

/* FOOTER */

.footer__container {
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
}

/* MEDIA QURIES (Small Screens) */
@media screen and (max-width: 600px) {
  /* MAIN HEADER */
  .main__header {
    margin-top: 0;
    padding: 12rem 0 0;
    height: 100vh;
  }

  .main__header-image,
  .main__header-circle {
    display: none;
  }

  .main__header-container {
    grid-template-columns: 1fr;
  }

  .main__header-container {
    height: 59%;
  }

  .main__header {
    width: 100vw;
  }

  /* SAFARIS */

  .safaris__wrapper {
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-top: 3rem;
  }

  .safaris__safari {
    width: 84%;
    margin: 0 auto;
  }

  /* VALUES */

  .values__wrapper {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .card.values__value {
    width: 84%;
    margin-inline: auto;
  }

  /* FAQS */

  .faqs__wrapper {
    gap: 1rem;
    margin-top: 3rem;
  }

  /* TESTIMONIALS */

  .testimonials {
    width: 95%;
  }

  /* FOOTER */

  footer {
    margin-top: 7rem;
  }

  .footer__container {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .footer-info {
    display: grid;
    grid-template-columns: 1fr;
  }

  .footer__container article {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer__container p {
    text-align: center;
  }

  small {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content inside the articles */
    margin-bottom: 3rem;
    padding: 5rem;
    font-size: 0.6rem;
    margin: -76px -101px -40px -100px;
  }

  .footer_footer {
    margin: 0 auto;
  }
}
