.main {
  width: 100%;
  height: 100vh;
}

video {
  width: 100%;
  /* height: 100%; */
  object-fit: contain;
}

.video__header-left {
  margin-top: 3rem;
  position: absolute;
  width: 40%;
  height: 100%;
  top: 22rem;
  left: 2rem;
  display: flex;
  flex-direction: column;
}

.main__header-left h3 {
  margin-bottom: 4rem;
  color: var(--color-secondary);
  font-weight: 600;
}

.main__header-left p {
  margin: 1rem 0 3.5rem;
  font-size: 1.1rem;
}

.btn {
  margin-left: 0px !important;
}
